:root {
  --baseline: 8px;
  --header-height: 57px;
  --font-size-md: 16px;
  --font-size-lg: 24px;
  --font-size-xlg: 70px;
  --font-family-base: "Inconsolata", monospace;
  --theme-light-primary-color: #000000;
  --theme-light-secondary-color: #ffffff;
  --theme-dark-primary-color: #ffffff;
  --theme-dark-secondary-color: #000000;
}
