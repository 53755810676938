[data-page="home"] .updates h2 {
  margin-bottom: calc(var(--baseline) * 2);
}

[data-page="home"] .updates a {
  display: block;
  padding: var(--baseline);
}

[data-page="home"] .updates article:not(:last-child) {
  margin-bottom: var(--baseline);
}
