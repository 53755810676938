.footer {
  margin-top: var(--header-height);
  padding-bottom: calc(var(--baseline) * 4);
}

.footer > .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .social {
  display: inline-flex;
}

.footer a:not(:last-child) {
  margin-right: calc(var(--baseline) * 2);
}

@media screen and (max-width: 768px) {
  .footer > .container {
    flex-direction: column;
  }

  .footer p {
    margin-bottom: var(--baseline);
  }
}
