.header {
  top: 0;
  width: 100%;
  z-index: 1;
  position: fixed;
  background: var(--secondary-color);
  line-height: 1.1;
  border-bottom: 1px solid var(--primary-color);
}

.header > .container {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header [tabindex="0"] {
  width: 1px;
  height: 1px;
  z-index: -999;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.header [tabindex="0"]:focus {
  width: auto;
  height: auto;
  z-index: 1;
  overflow: inherit;
  padding: 5px;
}

.header .menu {
  flex: 1;
  display: inline-flex;
  justify-content: flex-end;
}

.header .menu > a:not(:last-child) {
  margin-right: calc(var(--baseline) * 2);
}

.header [aria-current="page"]::after {
  left: 0;
  width: 100%;
  height: 2px;
  bottom: -6px;
  content: "";
  position: absolute;
  background: var(--primary-color);
}
